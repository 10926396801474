<template>
<loader v-bind="{ loading }">
    <columns>
        <column>
            <columns>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="helmet-safety" class="fa-2x has-text-grey"/>
                        <p>
                        Total # of Work Orders
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.total_work_orders | abbreviateCount }}</p>
                            <difference-tag :last="overview.work_orders_last_month" :current="overview.work_orders_this_month" />
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="helmet-safety" class="fa-2x has-text-grey"/>
                        <p>
                        # of Work Orders In Progress
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.in_progress_work_orders | abbreviateCount }}</p>
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="list-check" class="fa-2x has-text-grey"/>
                        <p>
                        Total # of Checklists
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.total_checklists | abbreviateCount }}</p>
                            <difference-tag :last="overview.checklists_last_month" :current="overview.checklists_this_month" />
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="stopwatch" class="fa-2x has-text-grey"/>
                        <p>
                        Total time spent on Checklists
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                        <p class="is-size-3">{{ overview.total_checklist_time }}</p>
                        </div>
                    </div>
                </column>
            </columns>
            <columns>
                <column class="is-3">
                    <div class="box is-round p-5">
                        <p class="is-size-4 has-text-weight-light mb-3">Average Checklist Time</p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between mb-3 mt-2">
                        <p class="is-size-1 has-text-weight-bold">
                            {{ overview.average_checklist_time }}
                        </p>
                        <!-- <span class="tag is-large is-rounded is-success">
                            <icon icon="chevron-down"/>
                            <span>22%</span>   
                        </span> -->
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box">
                        <work-carried-out-graph :summary="overview.checklist_graph"/>
                    </div>
                </column>
            </columns>
        </column>
        <column class="is-3">
            <p class="is-size-3">Overview</p>
            <p class="has-text-grey">{{ user.name }} is the leader of {{ overview.total_teams_lead }} team<span v-if="overview.total_teams_lead !== 1">s</span> and is part of {{ overview.total_teams }} team<span v-if="overview.total_teams !== 1">s</span> in total.</p>
            <!-- <div id="customer-equipment-health">
                <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
            </div> -->
            <p class="is-size-5 has-text-weight-bold">Recent Activity</p>
            <columns v-for="checklist in overview.recent_checklists" :key="checklist.id">
                <column>
                <p>
                    <router-link :to="{
                    name: 'checklist-manager',
                    params: {
                        checklist: checklist.uuid
                    }
                    }">
                        {{ checklist.checklist.name }}
                    </router-link>
                    <br>
                    <small>{{ checklist.inspectable.name }}</small>
                    <br>
                    <small class="has-text-grey">
                        At {{ checklist.work_order.location.name }}, Updated {{ checklist.updated_at | asCalendar }}
                    </small>
                </p>
                </column>
                <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar
                    tooltip="Current progress for this checklist"
                    :total="checklist.answers.length"
                    :total-complete="checklist.answers.filter(answer => answer.complete).length"
                />
                </column>
            </columns>
            <p class="has-text-centered mt-4 has-text-grey" v-if="!overview.recent_checklists.length">No recent activity to display.</p>

            <columns>
                <column>
                    <p class="is-size-5 has-text-weight-bold">Devices</p>
                    <ul>
                        <li v-for="device in overview.devices" :key="device.id">
                            {{ device.name }} - {{ device.version }}
                        </li>
                    </ul>
                </column>
            </columns>
        </column>
    </columns>
</loader>    
</template>
<script>
import { mapGetters } from 'vuex'
import WorkCarriedOutGraph from './partials/WorkCarriedOutGraph.vue'
export default {

    components: {
        WorkCarriedOutGraph
    },

    data: () => ({
        loading: true
    }),
    
    async beforeCreate() {
        await this.$store.dispatch('user/loadOverview', this.$route.params.user)
        this.loading = false
    },

    computed: {
        ...mapGetters('user', [
            'overview',
            'user'
        ])
    }

}
</script>